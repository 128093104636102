import React, { Component } from 'react'

export default class About extends Component {
  render() {
    console.log('About component is mounted');
    return (
      <div>
        <section className="colorlib-about" data-section="about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                  <div className="col-md-12">
                    <div className="about-desc">
                      <h2 className="colorlib-heading">Who Am I?</h2>
                      <p>
                        Hello! My name is <strong>Alaa Badreddine</strong>. My journey began in the vibrant landscapes of Guinea in 1990, where I was welcomed into the world by my loving parents. The early years of my life were spent absorbing the rich cultures of West Africa, first in Guinea and later in Mali, where my family relocated in 1998. In Mali, I completed my primary and secondary education, laying the groundwork for my academic pursuits in 2008.
                      </p>
                      <p>
                        In 2008, the quest for knowledge took me to Lebanon, where I studied Biology at the Lebanese University. It was in 2015 when I was graduated with a Bachelor's degree. Shortly after, I traveled to Marseille, France, to specialize further, achieving a Master's in Bioinformatics from Aix-Marseille University.
                      </p>
                      <p>
                        My master's internship at the Institute of Genetics and Molecular and Cellular Biology (IGBMC) in Strasbourg was a pivotal experience, focusing on transcriptome and exome analysis of prostate cancer patients. This work transitioned seamlessly into an engineering role at IGBMC, where our collective efforts led to the publication of our groundbreaking research.
                      </p>
                      <p>
                        In late 2018, I embraced a new opportunity at the Institute of Biology in Lille, working under the esteemed Pr. Philippe Froguel. I crafted a state-of-the-art pipelines for analyzing diabetic and obese patients. I was also responsible of managing the team's Linux servers. My contributions in Lille laid the foundation for the next chapter in my journey.
                      </p>
                      <p>
                        From early 2023 until today, I was thrilled to join Pr. Johan Auwerx's team at EPFL in Lausanne, Switzerland, as a Systems Specialist in Bioinformatics. I have various tasks, involving bioinformatics analysis, pipeline development, server management for the lab, user support for the members.
                      </p>
                      <p>
                        As of today, my career has been driven by a deep-seated passion for bioinformatics pipeline development and high-throughput data analysis. However, I have also developed a strong interest in web application development and Linux server administration.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 animate-box" data-animate-effect="fadeInLeft">
                    <div className="services color-1">
                      <span className="icon2"><i className="icon-bulb"></i></span>
                      <h3>Bioinformatician</h3>
                    </div>
                  </div>
                  <div className="col-md-3 animate-box" data-animate-effect="fadeInRight">
                    <div className="services color-2">
                      <span className="icon2"><i className="icon-globe-outline"></i></span>
                      <h3>System Administrator</h3>
                    </div>
                  </div>
                  <div className="col-md-3 animate-box" data-animate-effect="fadeInTop">
                    <div className="services color-3">
                      <span className="icon2"><i className="icon-data"></i></span>
                      <h3>Web Applications</h3>
                    </div>
                  </div>
                  <div className="col-md-3 animate-box" data-animate-effect="fadeInBottom">
                    <div className="services color-4">
                      <span className="icon2"><i className="icon-phone3"></i></span>
                      <h3>Programmer</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
